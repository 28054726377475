import { useEffect, useState } from 'react'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-components/shared/helpers/isValidArrayWithData'
import {
  ICardResponse,
  useCards,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import { ICheckoutV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'

type TSelectedCard = {
  id: string
  name: string
  valueUsed: string
  cardType: string
  cardId: string
  cardNumber: string
  entity: string
  gateway: string
  isDefault: boolean
  paymentId: string
  provider: string
  type: string
  quotes: boolean
}

type TUseSelectedCard = {
  selectedCard: Partial<TSelectedCard>
  checkout: Partial<ICheckoutV2Response>
  cards: ICardResponse
  refetchCheckout: () => void
}

export const useSelectedCard = (): TUseSelectedCard => {
  const { data: checkout, refetch: refetchCheckout } = useCheckoutV2()
  const { data: dataCards } = useCards({ version: 2 })
  const [selectedCard, setSelectedCard] = useState<Partial<TSelectedCard>>({})

  useEffect(() => {
    const validCard =
      (isValidArrayWithData(dataCards?.cards) &&
        dataCards?.cards.find((item) => {
          return (
            item?.id === checkout?.paymentInfo?.selectedPayments?.[0]?.cardId
          )
        })) ||
      {}
    const paymentMethod = checkout?.paymentInfo?.availablePaymentMethods.find(
      (method) => {
        return method?.id === validCard?.paymentId
      }
    )
    const card = {
      ...(checkout?.paymentInfo?.selectedPayments?.[0] || {}),
      ...{
        ...validCard,
        valueUsed: paymentMethod?.price,
        quotes: paymentMethod?.quotes || false
      }
    }

    setSelectedCard(card)
  }, [checkout])

  return {
    selectedCard,
    checkout,
    cards: dataCards?.cards,
    refetchCheckout
  }
}
