import { DEFAULT_NUMBER_OF_INSTALLMENTS } from 'shared/constants'
import { ICheckoutConfig } from '.'

/**
 * Default configuration for the checkout.
 */
export const defaultConfig: ICheckoutConfig = {
  termsAndConditions: false,
  loadingTransaction: false,
  isLoading: false,
  isEnablePaymentButton: false,
  orderformId: '',
  saleschannel: '',
  idToken: '',
  caToken: '',
  installments: DEFAULT_NUMBER_OF_INSTALLMENTS,
  installments_oneclick: DEFAULT_NUMBER_OF_INSTALLMENTS,
  installment_oneclick_chosen: '1',
  stores: [],
  showSaveBags: false,
  banner: null,
  footer: []
}

export const keyToQuery = 'checkoutConfig'

export const CHECKOUT_CONFIG_IDS = {
  UNIPAY_INSTALLMENTS: '1cGPLQnQOgXaN7hEUSifSk',
  STORES: 'GkeHX6VpWrgXWkk81UBZR',
  BANNER_WEB: '3OflKtChIwpUk98jc9EaUu',
  ONECLICK_INSTALLMENTS: '3JBQ3DCMcubEJuWI2SWbLV',
  INVOICE_UNIMARC: 'xHrkZmvrqvA6WSXaTPtEm'
}
