import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Body } from './components/body'
import { useEffect } from 'react'
import { sleep, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import styles from './styles.module.css'

export interface IModalInstallmentsProps {
  handleToggle: () => void
}

const TITLE_MODAL = 'Seleccionar cuotas'

export const ModalInstallments = ({
  handleToggle
}: IModalInstallmentsProps) => {
  const { isMobileV2 } = useMobile()

  const bemId = getBemId('modal', 'installments')

  const handleClose = async () => {
    if (!isMobileV2) {
      const modal = document.getElementById(bemId)
      modal.classList.remove(styles.openModal)
      await sleep(300)
    }
    handleToggle()
  }

  const handleOpen = async () => {
    const modal = document.getElementById(bemId)
    await sleep(300)
    modal.classList.add(styles.openModal)
  }

  useEffect(() => {
    if (!isMobileV2) handleOpen()
  }, [isMobileV2])

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId={bemId}
          bodyChildrenProps={{
            maxHeight: 'none',
            minHeight: 'calc(100vh - 161px)',
            overflow: 'auto',
            padding: '16px',
            alignItems: 'center'
          }}
          borderEdge='none'
          footerChildrenProps={{
            boxShadow: '0',
            default: false
          }}
          headerCloseIcon={{
            name: 'CloseThin',
            customSize: 20,
            default: true
          }}
          headerContainerProps={{
            minHeight: '68px',
            borderEdge: 'bottom'
          }}
          headerTitle={TITLE_MODAL}
          headerTitleProps={{
            fontWeight: 'semibold',
            default: true,
            fontSize: 'xl',
            lineHeight: 'normal'
          }}
          modalConfigsProps={{
            isOpen: true,
            containerId: bemId,
            minHeight: 'calc(100vh - 16px)',
            maxWidth: '480px',
            isWindowBlocked: true,
            customContainerClassName: styles.modalContainer,
            toggle: handleClose,
            toggleOutside: handleClose
          }}
          styleProps={{
            customHeightModalContent: '100%'
          }}
        >
          <Body handleClose={handleClose} />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='ModalUnipayMobile'
          body={<Body handleClose={handleClose} />}
          dragProps={{
            draggButton: false
          }}
          hasFooter={false}
          headerTitle={TITLE_MODAL}
          hideHeaderDivider={true}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton={false}
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
