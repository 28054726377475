import { Row } from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { ChildrenBaseContainerProps } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { Picture } from '@smu-chile/pkg-unimarc-components/stories/atoms/Picture/Picture'
import { useCheckoutV2, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig } from 'shared/hooks'
import {
  CODE_UNIPAY_WITH_INSTALLMENTS,
  CODE_UNIPAY_WITHOUT_INSTALLMENTS
} from 'shared/constants'
import styles from './styles.module.css'

export interface IBannerProps {
  handleOpenUnipay?: () => void
}

enum ReferenceType {
  Unipay = 'Unipay'
}

export const Banner = ({ handleOpenUnipay }: IBannerProps = {}) => {
  const { checkoutConfig } = useCheckoutConfig()
  const { data: dataCheckout } = useCheckoutV2()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 639

  const IMG_SRC = {
    desktop: checkoutConfig?.banner?.imageDesktop?.url,
    mobile: checkoutConfig?.banner?.imageMobile?.url
  }

  const data = checkoutConfig?.banner
  const srcImageName = isMobile ? 'imageMobile' : 'imageDesktop'
  const srcImage = isMobile ? IMG_SRC.mobile : IMG_SRC.desktop
  const configContainer: ChildrenBaseContainerProps = {
    maxWidth: '100%',
    customWidth: '100%',
    customHeight: isMobile ? '46px' : '100%',
    maxHeight: isMobile ? '46px' : '100%',
    overflow: 'hidden',
    alignSelf: 'center',
    borderRadius: '12px'
  }

  const handleOnClick = () => {
    const unipayCodes = [
      CODE_UNIPAY_WITH_INSTALLMENTS,
      CODE_UNIPAY_WITHOUT_INSTALLMENTS
    ]
    const hasUnipayPaymentMethod =
      dataCheckout?.paymentInfo?.availablePaymentMethods?.find(
        (paymentMethod) => {
          return unipayCodes.includes(paymentMethod.id)
        }
      )

    if (
      data?.referenceType === ReferenceType.Unipay &&
      hasUnipayPaymentMethod
    ) {
      handleOpenUnipay()
    }
  }

  if (!srcImage) return null

  return (
    <Row
      customClassName={styles.banner_container}
      id={getBemId('checkout', 'bannerContainer_banner')}
      onClick={handleOnClick}
      {...configContainer}
    >
      <Picture
        alt='Banner de checkout'
        clickable='pointer'
        height='100%'
        id={getBemId('checkout', 'banner', srcImageName)}
        src={srcImage}
        width='100%'
      />
    </Row>
  )
}
