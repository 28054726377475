import Image from 'next/image'
import { useState } from 'react'
import classNames from 'classnames'
import {
  Column,
  Icon,
  Picture,
  Row,
  Text,
  ToggleSwitch
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { normalizeString, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { PAYMENT_IMAGES, PAYMENT_METHODS } from 'shared/constants'
import { getLastFourNumbersOfCard } from 'shared/helpers/getLastFourNumbersOfCard'
import { useCheckoutConfig, useSelectedCard } from 'shared/hooks'

import styles from './styles.module.css'

interface ICardSelectedProps {
  handleToggleSelectedCard: () => void
  handleToggleModalInstallments: () => void
}

const CARD_SELECTED_TEXTS = {
  DEBIT: 'Débito',
  CREDIT: 'Crédito',
  PREPAGO: 'Prepago',
  TITLE: 'Mi tarjeta',
  CHANGE_CARD: 'Cambiar',
  SAVE_CARD: '¡Listo! Guardaremos los datos de tu tarjeta'
}

export const CardSelected = ({
  handleToggleSelectedCard,
  handleToggleModalInstallments
}: ICardSelectedProps) => {
  const [isSelectCheck, setIsSelectCheck] = useState(false)

  const { checkoutConfig } = useCheckoutConfig()
  const { selectedCard } = useSelectedCard()
  const { isMobileV2 } = useMobile()

  const installments = checkoutConfig.installment_oneclick_chosen
  const cardImage =
    PAYMENT_IMAGES[normalizeString(selectedCard?.entity || '')] ||
    PAYMENT_IMAGES[normalizeString(selectedCard?.cardType || '')] ||
    PAYMENT_IMAGES[normalizeString(selectedCard?.type || '')]
  const showInstallments = parseInt(installments) > 1

  const paymentType = () => {
    if (
      selectedCard?.type === PAYMENT_METHODS.DEBITCARD &&
      selectedCard?.cardType?.toLowerCase() === PAYMENT_METHODS.PREPAGO
    )
      return 'Prepago'
    if (selectedCard?.type === PAYMENT_METHODS.CREDITCARD) return 'Crédito'
    if (selectedCard?.type === PAYMENT_METHODS.DEBITCARD) return 'Débito'
    return ''
  }

  const handleToggleSelectCheck = () => {
    setIsSelectCheck(!isSelectCheck)
  }

  return (
    <Row
      border={`1px solid ${getGlobalStyle('--color-primary-light-red')}`}
      borderRadius={getGlobalStyle('--border-radius-md')}
      id={getBemId('card-selected', 'container')}
      padding='16px'
    >
      <Column gap={16}>
        {/* header */}
        <Row
          justifyContent='between'
          tagName='article'
        >
          <Text
            fontWeight='medium'
            id={getBemId('cardSelected', 'title', 'desktop')}
          >
            {CARD_SELECTED_TEXTS.TITLE}
          </Text>
          <Text
            clickable='pointer'
            customColor={getGlobalStyle('--color-primary-light-red')}
            fontWeight='medium'
            id={getBemId('cardSelected', 'cambiar', 'buttonDesktop')}
            lineHeight='17px'
            onClick={handleToggleSelectedCard}
          >
            {CARD_SELECTED_TEXTS.CHANGE_CARD}
          </Text>
        </Row>
        <Row
          alignItems='center'
          justifyContent='between'
          tagName='article'
        >
          <Row
            alignItems='center'
            gap={12}
          >
            <Row
              alignItems='center'
              customHeight={isMobileV2 ? '30px' : '42px'}
              maxWidth={isMobileV2 ? '30px' : '42px'}
            >
              <Picture
                height='100%'
                id={getBemId('cardSelected', 'cardImage', 'desktop')}
                nextImage={Image}
                src={cardImage}
                width='100%'
              />
            </Row>
            <Column gap={4}>
              <Text
                fontSize='lg'
                fontWeight='medium'
                id={getBemId('cardSelected', 'paymentType', 'desktop')}
              >
                {paymentType()}
              </Text>
              <Row
                alignItems='center'
                gap={5}
                maxWidth='max-content'
              >
                <Text
                  customColor={getGlobalStyle('--color-icons-gray')}
                  fontSize='md'
                  id={getBemId('cardSelected', 'lastNumberOfCards', 'desktop')}
                  lineHeight='14px'
                >
                  {`****${
                    getLastFourNumbersOfCard(selectedCard?.cardNumber) || ''
                  }`}
                </Text>
                {selectedCard?.quotes && (
                  <>
                    <Column
                      backgroundColor={getGlobalStyle(
                        '--color-neutral-gray-silver'
                      )}
                      customHeight='15px'
                      width={1}
                    />
                    <Row
                      alignItems='center'
                      borderRadius='10px'
                      clickable='pointer'
                      customClassName={classNames(styles['instalment-btn'], {
                        active: showInstallments
                      })}
                      justifyContent='center'
                      onClick={handleToggleModalInstallments}
                      padding='0 4px'
                    >
                      <Text
                        clickable='pointer'
                        customColor={getGlobalStyle('--color-icons-gray')}
                        fontSize='md'
                        id={getBemId('cardSelected', 'installments')}
                        lineHeight='14px'
                      >
                        {showInstallments
                          ? `${installments} cuotas`
                          : 'Sin Cuotas'}
                      </Text>
                      <Icon
                        clickable='pointer'
                        name='ArrowBackNavigate'
                      />
                    </Row>
                  </>
                )}
              </Row>
            </Column>
          </Row>
          <Row maxWidth='max-content'>
            <Text
              fontSize='lg'
              fontWeight={isMobileV2 ? 'semibold' : 'medium'}
            >
              {selectedCard?.valueUsed}
            </Text>
          </Row>
        </Row>
        <Row
          backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
          borderRadius={getGlobalStyle('--border-radius-2sm')}
          hidden
          justifyContent='between'
          padding='8px 16px'
        >
          <Text
            fontSize='md'
            fontWeight='medium'
          >
            {CARD_SELECTED_TEXTS.SAVE_CARD}
          </Text>
          <ToggleSwitch
            customClass={styles['toggle-switch']}
            onClick={handleToggleSelectCheck}
            value={!isSelectCheck}
          />
        </Row>
      </Column>
    </Row>
  )
}
