import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  Cookies,
  getMiniFooterContent,
  isValidArrayWithData,
  useCards,
  useCheckoutV2,
  useContentful,
  useOrders,
  useQuery,
  useQueryClient,
  UseQueryResult
} from '@smu-chile/pkg-unimarc-hooks'
import { IMiniFooter } from '@smu-chile/pkg-unimarc-components/shared/interfaces/IMiniFooter'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { validatePaymentButton } from './helpers'
import {
  CODE_KLAP_CREDIT,
  CODE_KLAP_DEBIT,
  CODE_ONECLICK_CREDIT,
  CODE_ONECLICK_DEBIT,
  DEFAULT_NUMBER_OF_INSTALLMENTS
} from 'shared/constants'
import { queryCheckoutConfig, queryGeneralConfig } from './queries'
import { CHECKOUT_CONFIG_IDS, defaultConfig, keyToQuery } from './constants'

export interface ICheckoutConfig {
  termsAndConditions: boolean
  loadingTransaction: boolean
  isLoading: boolean
  isEnablePaymentButton: boolean
  orderformId: string
  saleschannel: string
  caToken: string
  idToken: string
  installments: { id: string; text: string }[]
  installments_oneclick: { id: string; text: string }[]
  installment_oneclick_chosen?: string
  invoiceStores?: {
    nombre: string
    courierid: string
  }[]
  stores?: string[]
  showSaveBags?: boolean
  banner?: {
    sys: {
      id: string
    }
    label: string
    referenceType: string
    imageDesktop: {
      url: string
    }
    imageMobile: {
      url: string
    }
  }
  footer?: IMiniFooter[]
}

/**
 * Custom hook for managing the checkout configuration.
 * @returns An object containing the checkout configuration and a function to mutate it.
 * @example
 * const { checkoutConfig, mutateCheckoutConfig } = useCheckoutConfig()
 * mutateCheckoutConfig({ termsAndConditions: true })
 * console.log(checkoutConfig.termsAndConditions) // true
 * console.log(checkoutConfig.loadingTransaction) // false
 * console.log(checkoutConfig.isLoading) // false
 */
export const useCheckoutConfig = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { isLoading: isLoadingCheckout, data: checkout } = useCheckoutV2()
  const { isLoading: isLoadingCards, data: dataCards } = useCards({
    version: 2
  })
  const { isLoading: isLoadingOrders, data: orders } = useOrders({
    reactQuery: {
      enabled: true,
      refetchOnWindowFocus: false,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })
  const { data: contentfulData } = useContentful({
    id_contentful: 'checkout_config',
    reactQuery: {
      queryKey: 'checkout_config'
    },
    graphQL: {
      query: queryCheckoutConfig
    }
  }) as UseQueryResult<{
    data: {
      viewBlock: {
        itemsCollection: {
          items: {
            sys: {
              id: string
            }
            cuotas: string[]
            itemsCollection: {
              items: {
                nombre: string
                courierid: string
              }[]
            }
          }[]
        }
      }
    }
  }>
  const configData = useContentful({
    id_contentful: 'configuracionesGenerales',
    reactQuery: {
      queryKey: 'general_config'
    },
    graphQL: {
      query: queryGeneralConfig
    }
  }) as UseQueryResult<{
    data: {
      configuracionesGenerales: {
        mostrarBolsasCheckout: boolean
      }
    }
  }>
  const { data: footerDataCf, isLoading: isLoadingFooter } = useContentful({
    id_contentful: 'contentful_minifooter',
    options: {
      content_type: 'unimarcWebTopFooter',
      'fields.label[in]': 'Mini Footer (Desktop)',
      'fields.platform': 'Web Unimarc eComm'
    }
  })

  const isLoading: boolean = isLoadingCheckout || isLoadingCards
  const getCookies: string = Cookies.get(keyToQuery) || '{}'
  const parsedCookies: ICheckoutConfig = JSON.parse(getCookies)
  const initialData: ICheckoutConfig = {
    ...defaultConfig,
    orderformId: parsedCookies.orderformId || defaultConfig.orderformId,
    stores: parsedCookies.stores || defaultConfig.stores,
    footer: parsedCookies.footer || defaultConfig.footer,
    termsAndConditions:
      parsedCookies.termsAndConditions || defaultConfig.termsAndConditions
  }

  // Fetches the checkout configuration from the server
  const { data } = useQuery(
    keyToQuery,
    () => {
      return initialData
    },
    {
      enabled: false,
      initialData
    }
  )

  /**
   * Mutates the checkout configuration.
   * @param props - The properties to update in the configuration.
   */
  const mutate = (props: Partial<ICheckoutConfig>): void => {
    const prevData: ICheckoutConfig =
      queryClient.getQueryData(keyToQuery) || initialData
    const newData = {
      ...initialData,
      ...prevData,
      ...data,
      isLoading,
      ...props
    }

    Cookies.set(keyToQuery, JSON.stringify(newData), {
      expires: new Date(new Date().getTime() + 5 * 60 * 1000) // 5 minutes
    })
    queryClient.setQueryData(keyToQuery, newData)
  }

  /**
   * Gets the total amount of the checkout and giftcards
   * @returns The total amount of the checkout.
   */
  const getTotalAmountByCards = () => {
    let totalAmount = checkout?.value

    if (isValidArrayWithData(checkout?.paymentInfo?.availablePaymentMethods)) {
      const getPaymentMethods = checkout?.paymentInfo?.availablePaymentMethods
      const paymentMethodsWithoutDiscount = [
        CODE_ONECLICK_DEBIT,
        CODE_ONECLICK_CREDIT,
        CODE_KLAP_DEBIT,
        CODE_KLAP_CREDIT
      ]
      const getFirstPaymentWitoutDiscount = getPaymentMethods?.find(
        (payment) => {
          return paymentMethodsWithoutDiscount.includes(payment.id)
        }
      )
      totalAmount = getFirstPaymentWitoutDiscount?.price ?? checkout?.value
    }

    return totalAmount
  }

  // Updates the checkout configuration when the loading status changes
  useEffect(() => {
    mutate({ isLoading })
  }, [isLoadingCheckout, isLoadingCards])

  // Updates the validation of the payment button
  useEffect(() => {
    const handleValidationGeneralData = async () => {
      let installments = DEFAULT_NUMBER_OF_INSTALLMENTS
      let installments_oneclick = DEFAULT_NUMBER_OF_INSTALLMENTS
      let stores = []
      let showSaveBags = false
      let banner = null
      let invoiceStores = null

      if (contentfulData) {
        const itemsCollection =
          contentfulData?.data?.viewBlock?.itemsCollection?.items

        // find the unipay installments in the contentful
        installments = itemsCollection
          ?.find((item) => {
            return item?.sys?.id === CHECKOUT_CONFIG_IDS.UNIPAY_INSTALLMENTS
          })
          ?.cuotas?.map((installment) => {
            return parseInt(installment)
          })
          ?.sort((a, b) => {
            return a - b
          })
          ?.map((installment) => {
            const text =
              installment === 1 ? 'Sin cuotas' : `${installment} cuotas`

            return {
              id: installment.toString(),
              text
            }
          })

        // find the oneclick installments in the contentful
        installments_oneclick = itemsCollection
          ?.find((item) => {
            return item?.sys?.id === CHECKOUT_CONFIG_IDS.ONECLICK_INSTALLMENTS
          })
          ?.cuotas?.map((installment) => {
            return parseInt(installment)
          })
          ?.sort((a, b) => {
            return a - b
          })
          ?.map((installment) => {
            const text =
              installment === 1 ? 'Sin cuotas' : `${installment} cuotas`
            return {
              id: installment.toString(),
              text
            }
          })

        // find the banner in the contentful
        banner = itemsCollection?.find((item) => {
          return item?.sys?.id === CHECKOUT_CONFIG_IDS.BANNER_WEB
        })

        // find the invoice in the contentful
        invoiceStores = itemsCollection?.find((item) => {
          return item?.sys?.id === CHECKOUT_CONFIG_IDS.INVOICE_UNIMARC
        })?.itemsCollection?.items

        // general config
        if (configData?.data) {
          //  find the save bags in the contentful
          const configShowSaveBags =
            configData.data.data.configuracionesGenerales
              .mostrarBolsasCheckout ?? false
          // find the stores in the contentful
          stores =
            itemsCollection?.find((item) => {
              return item?.sys?.id === CHECKOUT_CONFIG_IDS.STORES
            })?.itemsCollection?.items || []
          const listCourierids = stores?.map((store) => {
            return store?.courierid
          })
          const currentCourierId = checkout?.shipping?.address?.courierId
          const shippingAddressIntoTheStores =
            listCourierids?.includes(currentCourierId)
          showSaveBags = configShowSaveBags && shippingAddressIntoTheStores
        }
      }

      mutate({
        installments,
        stores,
        showSaveBags,
        banner,
        installments_oneclick,
        invoiceStores
      })
    }

    handleValidationGeneralData()
  }, [])

  useEffect(() => {
    Cookies.set(keyToQuery, JSON.stringify(data), {
      expires: new Date(new Date().getTime() + 5 * 60 * 1000) // 5 minutes
    })

    const isEnablePaymentButton = validatePaymentButton({
      checkout,
      cards: dataCards?.cards,
      termsAndConditions: data.termsAndConditions
    })
    mutate({ isEnablePaymentButton })
  }, [data, checkout, dataCards?.cards])

  // If the user has orders, it will set the terms and conditions as true
  useEffect(() => {
    if (!data.termsAndConditions) {
      mutate({
        termsAndConditions:
          isValidArrayWithData(orders?.data?.orders) ||
          isValidArrayWithData(dataCards?.cards)
      })
    }
  }, [orders, isLoadingOrders, dataCards?.cards])

  useEffect(() => {
    if (checkout?.status === 401) Cookies.remove(keyToQuery)
  }, [checkout, dataCards?.cards])

  useEffect(() => {
    if (isLoadingFooter) return
    const footerData: IMiniFooter[] = getMiniFooterContent(
      footerDataCf,
      router
    ) as IMiniFooter[]
    if (isValidArrayWithData(footerData)) mutate({ footer: footerData })
  }, [footerDataCf, isLoadingFooter])

  return {
    checkoutConfig: data,
    mutateCheckoutConfig: mutate,
    getTotalAmountByCards
  }
}
