import Image from 'next/image'
import {
  Column,
  Icon,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { normalizeString, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { PAYMENT_IMAGES, PAYMENT_METHODS } from 'shared/constants'
import { getLastFourNumbersOfCard } from 'shared/helpers'
import { useCheckoutConfig, useSelectedCard } from 'shared/hooks'

export type BodyProps = {
  handleClose: () => void
}

export const Body = ({ handleClose }: BodyProps) => {
  const { checkoutConfig, mutateCheckoutConfig } = useCheckoutConfig()
  const { selectedCard } = useSelectedCard()
  const { isMobileV2 } = useMobile()

  const installments = checkoutConfig.installment_oneclick_chosen
  const installmentsOneclick = checkoutConfig.installments_oneclick
  const cardImage =
    PAYMENT_IMAGES[normalizeString(selectedCard?.entity || '')] ||
    PAYMENT_IMAGES[normalizeString(selectedCard?.cardType || '')] ||
    PAYMENT_IMAGES[normalizeString(selectedCard?.type || '')]

  const paymentType = () => {
    if (
      selectedCard?.type === PAYMENT_METHODS.DEBITCARD &&
      selectedCard?.cardType?.toLowerCase() === PAYMENT_METHODS.PREPAGO
    )
      return 'Prepago'
    if (selectedCard?.type === PAYMENT_METHODS.CREDITCARD) return 'Crédito'
    if (selectedCard?.type === PAYMENT_METHODS.DEBITCARD) return 'Débito'
    return ''
  }

  const handleChooseInstallments = (installmentId: string) => {
    mutateCheckoutConfig({
      installment_oneclick_chosen: installmentId
    })
    handleClose()
  }

  return (
    <Column
      customWidth='100%'
      maxWidth='421px'
    >
      <Row
        border='1.3px solid #E6E6E6'
        borderColor={getGlobalStyle('--color-primary-light-red')}
        borderRadius='12px'
        customWidth='100%'
        gap={8}
        padding='16px'
      >
        <Row
          alignItems='center'
          customHeight={isMobileV2 ? '34px' : '42px'}
          maxWidth={isMobileV2 ? '34px' : '42px'}
        >
          <Picture
            height='100%'
            id={getBemId('modalInstallments', 'cardImage')}
            nextImage={Image}
            src={cardImage}
            width='100%'
          />
        </Row>
        <Column gap={4}>
          <Text
            fontSize={isMobileV2 ? 'md' : 'base'}
            fontWeight='semibold'
          >
            {paymentType()}
          </Text>
          <Text
            fontSize='sm'
            lineHeight='14px'
          >
            {`****${getLastFourNumbersOfCard(selectedCard?.cardNumber) || ''}`}
          </Text>
        </Column>
      </Row>
      <Spacer.Horizontal customSize={32} />
      <Column gap={8}>
        <Row>
          <Text
            fontSize='base'
            fontWeight='medium'
            lineHeight='14px'
          >
            Escoge tu Nº de cuotas
          </Text>
        </Row>
        <Column>
          {installmentsOneclick?.map((installment) => {
            const isSelected = installments === installment.id
            const customBackgroundColor = isSelected
              ? getGlobalStyle('--color-neutral-gray-white')
              : getGlobalStyle('--color-neutral-white')

            return (
              <Row
                alignItems='center'
                backgroundColor={customBackgroundColor}
                clickable='pointer'
                customHeight='40px'
                justifyContent='between'
                key={installment.id}
                onClick={() => {
                  return handleChooseInstallments(installment.id)
                }}
                padding='11px 16px'
              >
                <Text
                  clickable='pointer'
                  fontSize='md'
                  lineHeight='normal'
                >
                  {installment.text}
                </Text>
                <Icon
                  clickable='pointer'
                  color={getGlobalStyle('--color-base-black')}
                  customSize={24}
                  name='ArrowRightNavigate3'
                />
              </Row>
            )
          })}
        </Column>
      </Column>
      <Spacer.Horizontal customSize={8} />
      <Row
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
        borderRadius='12px'
        gap={8}
        padding='8px 12px'
      >
        <Icon
          color={getGlobalStyle('--color-feedback-warning-light')}
          customSize={18}
          name='WarningFilled'
        />
        <Text fontSize='sm'>
          El monto final a pagar dependerá de las condiciones aplicadas por tu
          banco.
        </Text>
      </Row>
    </Column>
  )
}
