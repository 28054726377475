export const CHECKOUT_CONFIG = '6Ga7kt8efFpybsEp3CZmeS'

export const queryCheckoutConfig = `
query checkoutConfig {
  viewBlock(id: "6Ga7kt8efFpybsEp3CZmeS") {
    itemsCollection(limit: 10, where: {sys: {id_exists: true}}) {
      items {
        ... on NavigationItem {
          sys {
            id
          }
          label
          subtitulo
          action
          navigateTo
          url
          icon {
            url
          }
        }
        ... on ViewBlock {
          sys {
            id
          }
          blockId
          description
          itemsCollection(limit: 5) {
            items {
              ... on Local {
                nombre
                courierid
              }
            }
          }
        }
        ... on InfoUnipay {
          sys {
            id
          }
          title
          cuotas
        }
        ... on ImageBannerWeb {
          sys {
            id
          }
          label
          referenceType
          imageDesktop {
            url
          }
          imageMobile {
            url
          }
        }
        ... on TermsAndConditionsProd {
          sys{
            id
          }
          name
          value{
            json
          }
        }
      }
    }
  }
}
`

export const queryGeneralConfig = `
query generalConfig {
  configuracionesGenerales(id:"dsoTlzKTxRYHggH1Icafc"){
    label
    mostrarBolsasCheckout
  }
}
`
