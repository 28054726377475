import { useEffect, useState } from 'react'
import {
  Column,
  DesktopModalWrapper,
  Text,
  MobileModalWrapper,
  Icon,
  Row,
  Button
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  normalizeString,
  patchPayment,
  trigger,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import {
  CARD_TYPE_CATEGORY,
  PAYMENT_CARD_TYPES,
  TOAST_MESSAGES
} from 'shared/constants'
import {
  ICard,
  ICardResponse
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICards'
import { IEcommerceError } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IEcommerceError'
import { useCheckoutConfig, useDataLayers, useSelectedCard } from 'shared/hooks'
import { getCardsToList } from 'shared/helpers/getCardsToList'
import { THandleToggleDeleteCardModal } from '../helpers'
import { CardItem } from '../CardItem'
import styles from '../styles.module.css'

interface IModalListNewPaymentsProps {
  handleShowMethods: () => void
  handleToggle: () => void
  handleToggleDeleteCardModal: THandleToggleDeleteCardModal
}

const titleDictionaryCards = {
  debit: 'Débito',
  credit: 'Crédito'
}
const TITLE_MODAL = 'Mis tarjetas'

export const ModalListPaymentsCards = ({
  handleShowMethods,
  handleToggle,
  handleToggleDeleteCardModal
}: IModalListNewPaymentsProps) => {
  // UI
  const [isLoading, setIsLoading] = useState(false)
  const {
    selectedCard: currentSelectedCard,
    checkout,
    cards,
    refetchCheckout
  } = useSelectedCard()
  const [selectedCard, setSelectedCard] = useState(currentSelectedCard?.cardId)

  const { mutateCheckoutConfig } = useCheckoutConfig()
  const { getErrorEvent } = useDataLayers()
  // ARRANGE
  const getJustCards: ICardResponse =
    (isValidArrayWithData(cards) &&
      cards.filter((item) => {
        return (
          normalizeString(item.cardType) !==
          normalizeString(PAYMENT_CARD_TYPES.GIFTCARD)
        )
      })) ||
    []
  const cardsToList = getCardsToList({ getJustCards, checkout })

  const total = checkout?.value

  const handleSelectCard = async (item: ICard) => {
    setSelectedCard(item.id)
  }

  const handleSetCard = async () => {
    const item =
      isValidArrayWithData(getJustCards) &&
      getJustCards.find((item) => {
        return item.id === selectedCard
      })
    const nameType = item?.type === 'debit' ? 'Débito' : 'Crédito'
    const name = `${item?.gateway} ${nameType}`

    setIsLoading(true)

    const response = await patchPayment({
      data: [
        {
          id: item?.paymentId,
          name,
          cardId: item?.id,
          cardType: item?.type,
          valueUsed: total
        }
      ]
    })

    const statusString = response?.status.toString()
    const isValidStatus = /^20[02]$/.test(statusString)

    if (!isValidStatus) {
      const responseErrorAssertion = response?.data as IEcommerceError
      handleToggle()
      const errorCode = getErrorEvent({
        endpoint: 'payment',
        error: responseErrorAssertion?.error
      })
      trigger({
        eventType: 'checkoutToast',
        data: {
          show: true,
          codeError:
            errorCode?.show_code ||
            responseErrorAssertion?.code ||
            statusString,
          success: false,
          toastMessage: TOAST_MESSAGES.error_setcard
        }
      })
      return
    }
    if (isValidStatus && isValidArrayWithData(response?.data?.totals)) {
      await refetchCheckout()
      setIsLoading(false)
      handleToggle()
    }
    mutateCheckoutConfig({ installment_oneclick_chosen: '1' })
  }

  useEffect(() => {
    if (isValidArrayWithData(checkout?.paymentInfo?.selectedPayments)) {
      setSelectedCard(checkout?.paymentInfo?.selectedPayments?.[0]?.cardId)
    }
  }, [checkout])

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='modalListPaymentsCardsDesktop'
          bodyChildrenProps={{
            minHeight: '240px',
            maxHeight: '240px',
            padding: '20px 16px'
          }}
          bodyCustomClass={styles['container_body']}
          footerChildrenProps={{
            default: false,
            children: (
              <>
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  fullWidth
                  id={getBemId(
                    'modalListPaymentsCards',
                    'continueButton',
                    'desktop'
                  )}
                  isLoading={isLoading}
                  label='Continuar'
                  onClick={handleSetCard}
                  padding='8px 16px'
                  status={
                    !selectedCard || selectedCard?.length === 0
                      ? 'disabled'
                      : 'initial'
                  }
                />
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  fontWeight='500'
                  fullWidth
                  id={getBemId(
                    'modalListPaymentsCards',
                    'selectAnotherPaymentOptionButton',
                    'desktop'
                  )}
                  label='Seleccionar otro medio de pago'
                  onClick={handleShowMethods}
                  padding='8px 16px'
                  type='secondary-gray'
                />
              </>
            )
          }}
          headerCloseIcon={{
            customSize: 13,
            default: true
          }}
          headerCustomClass={styles.container_header}
          headerTitle={TITLE_MODAL}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '440px',
            isOpen: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          styleProps={{
            padding: '0',
            customHeightModalContent: 'initial'
          }}
        >
          <Column
            gap={16}
            minHeight='initial'
          >
            <Column
              alignItems='center'
              gap={12}
            >
              {/* show cards */}
              {cardsToList.map((item) => {
                return (
                  <CardItem
                    amountOfCards={getJustCards.length}
                    cardId={item?.id}
                    handleToggleDeleteCardModal={handleToggleDeleteCardModal}
                    iconProps={{
                      color: getGlobalStyle('--color-primary-light-red'),
                      name: 'Trash'
                    }}
                    image={item?.image}
                    isActive={selectedCard === item?.id}
                    key={item?.id}
                    onClick={() => {
                      handleSelectCard(item)
                    }}
                    showDiscountColor={item.hasDiscount}
                    subTitle={item?.subTitle}
                    title={titleDictionaryCards[CARD_TYPE_CATEGORY[item?.type]]}
                    total={item?.price || total}
                  />
                )
              })}
              {/* security message */}
              <Column
                backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='100px'
                gap={16}
                margin='0 0 30px 0'
                minWidth='380px'
                padding='16px 12px'
              >
                <Column gap={10}>
                  <Row gap={4}>
                    <Icon
                      color={getGlobalStyle('--color-feedback-warning-light')}
                      customSize={20}
                      name='Shield'
                    />
                    <Text
                      fontSize='md'
                      fontWeight='semibold'
                    >
                      Seguridad de pago
                    </Text>
                  </Row>
                  <Column>
                    <Text fontSize='sm'>
                      No compartimos los datos de tus tarjetas con nadie. Tu
                      información de pago está segura con nosotros.
                    </Text>
                  </Column>
                </Column>
              </Column>
            </Column>
          </Column>
        </DesktopModalWrapper>
      </BigScreen>

      <SmallScreen>
        <MobileModalWrapper
          blockId='modalListPaymentsCardsMobile'
          body={
            <Column
              alignItems='center'
              customHeight='100%'
              gap={16}
            >
              {cardsToList.map((item) => {
                return (
                  <CardItem
                    amountOfCards={getJustCards.length}
                    cardId={item?.id}
                    handleToggleDeleteCardModal={handleToggleDeleteCardModal}
                    iconProps={{
                      color: getGlobalStyle('--color-primary-light-red'),
                      name: 'Trash',
                      customSize: 20
                    }}
                    image={item?.image}
                    isActive={selectedCard === item?.id}
                    key={item?.id}
                    onClick={() => {
                      handleSelectCard(item)
                    }}
                    showDiscountColor={item.hasDiscount}
                    subTitle={item?.subTitle}
                    title={titleDictionaryCards[CARD_TYPE_CATEGORY[item?.type]]}
                    total={item?.price || total}
                  />
                )
              })}

              <Column
                backgroundColor={getGlobalStyle('--color-neutral-gray-white')}
                borderRadius={getGlobalStyle('--border-radius-md')}
                customHeight='100px'
                gap={16}
                minWidth='350px'
                padding='16px 12px'
              >
                <Column gap={10}>
                  <Row gap={4}>
                    <Icon
                      color={getGlobalStyle('--color-feedback-warning-light')}
                      customSize={20}
                      name='Shield'
                    />
                    <Text
                      fontSize='md'
                      fontWeight='semibold'
                    >
                      Seguridad de pago
                    </Text>
                  </Row>
                  <Column>
                    <Text fontSize='sm'>
                      No compartimos los datos de tus tarjetas con nadie.
                    </Text>
                    <Text fontSize='sm'>
                      Tu información de pago está segura con nosotros.
                    </Text>
                  </Column>
                </Column>
              </Column>
              <Row margin='50px 0'>&nbsp;</Row>
            </Column>
          }
          customFooter={
            <Column gap={16}>
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fontWeight='500'
                fullWidth
                id={getBemId(
                  'modalListPaymentsCards',
                  'continueButton',
                  'mobile'
                )}
                isLoading={isLoading}
                label='Continuar'
                onClick={handleSetCard}
                padding='8px 16px'
                status={
                  !selectedCard || selectedCard?.length === 0
                    ? 'disabled'
                    : 'initial'
                }
              />
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fontWeight='500'
                fullWidth
                id={getBemId(
                  'modalListPaymentsCards',
                  'selectAnotherPaymentOptionButton',
                  'mobile'
                )}
                label='Seleccionar otro medio de pago'
                onClick={handleShowMethods}
                padding='8px 16px'
                type='secondary-gray'
              />
            </Column>
          }
          dragProps={{
            draggButton: false
          }}
          headerCloseIconProps={{
            customSize: 13
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          headerTitle={TITLE_MODAL}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleToggle,
            toggleOutside: handleToggle
          }}
          onClose={handleToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
