import {
  dataLayerUniqueTypeEvent,
  ICardResponse,
  ICardResponseV2,
  isValidArrayWithData,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { ICheckoutV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'
import { IErrorNewBFF } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IErrorNewBFF'
import { CARD_TYPES, ERROR_MAP, ERROR_UNIPAY_MAP_MARKS } from './contants'

export interface IPublishEvent {
  error: string
  endpoint: keyof typeof ERROR_MAP
}

export const useDataLayers = () => {
  const queryClient = useQueryClient()

  // get cache data
  let checkoutData: ICheckoutV2Response =
    queryClient.getQueryData('/checkoutV2')

  let cardsData: ICardResponse & ICardResponseV2 & IErrorNewBFF =
    queryClient.getQueryData('/cards')

  // get the previous data if there is an error
  if (checkoutData?.error) {
    checkoutData = queryClient.getQueryCache().find('/checkoutV2')?.revertState
      ?.data as typeof checkoutData
  }
  if (cardsData?.error) {
    cardsData = queryClient.getQueryCache().find('/cards')?.revertState
      ?.data as typeof cardsData
  }

  // parse data
  const paymentInfo = checkoutData?.paymentInfo?.selectedPayments?.[0]
  const paymentInfoWithCard = {
    ...paymentInfo,
    ...(cardsData?.cards?.find((card) => {
      return card?.id === paymentInfo?.cardId
    }) || {})
  }
  const isActiveGiftcard =
    isValidArrayWithData(checkoutData?.paymentInfo?.giftCards) &&
    checkoutData?.paymentInfo?.giftCards?.some((giftCard) => {
      return giftCard?.isActive
    })

  // find the error code
  const getErrorEvent = ({ error, endpoint }: IPublishEvent) => {
    const getError = ERROR_MAP[endpoint].find((errorMap) => {
      const regex = new RegExp(errorMap.message, 'gm')
      return regex.test(error)
    })

    return getError
  }

  // function to push event
  const pushEvent = ({ error, endpoint }: IPublishEvent) => {
    if (typeof window === 'undefined') return

    // build event to push
    const eventToPush = {
      event: 'page_view',
      tipo_pagina: 'Checkout Error',
      payment_type:
        paymentInfoWithCard?.gateway || (isActiveGiftcard ? 'Giftcard' : ''),
      card_type:
        CARD_TYPES[paymentInfoWithCard?.cardType] ||
        CARD_TYPES[paymentInfoWithCard?.type] ||
        (isActiveGiftcard ? 'Giftcard' : ''),
      error_value: 'GEN-001'
    }

    const getError = ERROR_MAP[endpoint].find((errorMap) => {
      const regex = new RegExp(errorMap.message, 'gm')
      return regex.test(error)
    })

    if (getError) {
      eventToPush.error_value = getError.show_code
    }

    dataLayerUniqueTypeEvent(eventToPush)
  }

  // function to push the checkout events
  const pushCheckoutEvent = ({ error, endpoint }: IPublishEvent) => {
    if (typeof window === 'undefined') return
    const eventToPush = {
      event: 'interacciones_checkout',
      categoria_evento: 'checkout',
      accion_evento: 'notificacion_error',
      etiqueta_evento: 'error_transaccion',
      payment_type:
        paymentInfoWithCard?.gateway || (isActiveGiftcard ? 'Giftcard' : ''),
      card_type:
        CARD_TYPES[paymentInfoWithCard?.cardType] ||
        CARD_TYPES[paymentInfoWithCard?.type] ||
        (isActiveGiftcard ? 'Giftcard' : ''),
      error_code: error
    }

    const getError = ERROR_MAP[endpoint].find((errorMap) => {
      const regex = new RegExp(errorMap.message, 'gm')
      return regex.test(error)
    })

    if (getError) {
      eventToPush.error_code = getError.show_code
    }

    dataLayerUniqueTypeEvent(eventToPush)
  }

  // function to push the checkout unipay events
  const pushCheckoutUnipayEvent = ({
    error,
    cuotas,
    tag_event,
    action,
    customData = {},
    hasCuotas = true
  }: Partial<{
    cuotas: number
    error: string
    tag_event: string
    action: string
    customData: object
    hasCuotas?: boolean
  }>) => {
    if (typeof window === 'undefined') return

    const eventToPush = {
      event: 'interacciones_checkout',
      categoria_evento: 'pago_unipay',
      accion_evento: 'error',
      etiqueta_evento: 'generico',
      payment_type: 'Unipay',
      cuotas: cuotas?.toString() ?? '1',
      ...customData
    }

    if (!hasCuotas) {
      delete eventToPush.cuotas
    }

    if (action) {
      eventToPush.accion_evento = action
    }

    if (tag_event) {
      eventToPush.etiqueta_evento = tag_event
    } else {
      const getError = ERROR_UNIPAY_MAP_MARKS.find((errorMap) => {
        return errorMap.code_errors.includes(error)
      })

      if (getError) {
        eventToPush.etiqueta_evento = getError.tag_event
      }
    }

    dataLayerUniqueTypeEvent(eventToPush)
  }

  return {
    pushEvent,
    getErrorEvent,
    pushCheckoutEvent,
    pushCheckoutUnipayEvent
  }
}
