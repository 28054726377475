export const CARD_TYPES = {
  debit: 'Débito',
  credit: 'Crédito',
  Prepago: 'Prepago'
}

// errors from https://it-smu.atlassian.net/wiki/spaces/Ecommerce1/pages/664338519/Errores
export const ERROR_MAP = {
  checkout: [
    {
      show_code: 'CHK-001',
      message:
        '<CheckoutRepositoryVtex - find>: error when trying to obtain checkout'
    },
    {
      show_code: 'CHK-002',
      message:
        '<CheckoutRepositoryVtex - simulate>: error when trying to simulate checkout'
    },
    {
      show_code: 'CHK-003',
      message: '<SelectedDeliveryWindow> invalid window selected'
    },
    {
      show_code: 'CHK-004',
      message: '<SelectedPayment> invalid method selected'
    },
    {
      show_code: 'CHK-005',
      message:
        'VtexPaymentMethod - selectCorrectPaymentSystemForVtex: payment method not found'
    },
    {
      show_code: 'CHK-006',
      message: '<ClientData> invalid channel'
    },
    {
      show_code: 'CHK-007',
      message: 'Payments - selectCorrectPaymentSystem: payment method not found'
    },
    {
      show_code: 'CHK-008',
      message: 'Payments - findPaymentName: payment method not found'
    },
    {
      show_code: 'CHK-009',
      message: '<ShippingData> invalid address type'
    },
    {
      show_code: 'CHK-019',
      message:
        '<CheckoutMarketingVtexRepository - update>: error trying to update marketing data'
    },
    {
      show_code: 'CHK-031',
      message:
        '<CheckoutMarketingVtexRepository - getMarketingInfo>: error trying to get marketing data'
    },
    {
      show_code: 'CHK-042',
      message: '<CookieExtractor>: no cookies in request'
    }
  ],
  payment: [
    {
      show_code: 'CHK-010',
      message:
        '<CheckoutPaymentRepositoryVtex - update>: error when trying to update payment'
    },
    {
      show_code: 'CHK-011',
      message:
        '<CheckoutPaymentRepositoryVtex - remove>: error when trying to remove payment'
    },
    {
      show_code: 'CHK-012',
      message:
        '<CheckoutPaymentRepositoryVtex - getCheckoutAmount>: error when trying to get amount'
    },
    {
      show_code: 'CHK-013',
      message:
        '<CheckoutPaymentRepositoryVtex - ensureAmountsAreCorrectToPay>: no products on cart'
    },
    {
      show_code: 'CHK-014',
      message:
        '<CheckoutPaymentRepositoryVtex - ensureAmountsAreCorrectToPay>: amounts are incorrects to pay'
    },
    {
      show_code: 'CHK-015',
      message:
        '<VtexCustomDataRepository - update>: error when trying to update custom data'
    },
    {
      show_code: 'CHK-016',
      message:
        '<VtexCustomDataRepository - remove>: error when trying to remove custom data'
    },
    {
      show_code: 'CHK-017',
      message:
        '<VtexCustomDataRepository - update in batch>: error when trying to update custom data'
    },
    {
      show_code: 'CHK-018',
      message:
        '<VtexCustomDataRepository - remove in batch>: error when trying to remove custom data'
    }
  ],
  coupon: [
    {
      show_code: 'CHK-022',
      message:
        '<CheckoutCouponVtexRepository - setCoupon>: error in coupon assignment'
    },
    {
      show_code: 'CHK-033',
      message:
        '<CheckoutCouponVtexRepository - validateCurrentCoupon>: error in coupon validation'
    }
  ],
  cards: [
    {
      show_code: 'CHK-032',
      message:
        '<UserCardsMongoRepository - find>: paymentId for provider not configurated'
    },
    {
      show_code: 'CHK-043',
      message: '<ms-checkout <MicroserviceCardsRepository>> failed by timeout'
    }
  ],
  giftcards: [
    {
      show_code: 'CHK-034',
      message:
        '<PaymentProviderGeocomRepository - getToken> Error on login request to GEOCOM'
    },
    {
      show_code: 'CHK-037',
      message: 'problem setting a giftcard with vtex'
    },
    {
      show_code: 'CHK-038',
      message: 'failed request with geocom, failed operation'
    },
    {
      show_code: 'CHK-039',
      message: '<updateGiftcard> invalid giftcard values'
    }
  ],
  transaction: [
    {
      show_code: 'CHK-023',
      message:
        '<TransactionRepositoryVtex - findValuesToValidate>: error when trying to get checkout'
    },
    {
      show_code: 'CHK-024',
      message:
        '<TransactionRepositoryVtex - findValuesToValidate>: no delivery windows in checkout'
    },
    {
      show_code: 'CHK-025',
      message:
        '<TransactionRepositoryVtex - findValuesToValidate>: no items in checkout'
    },
    {
      show_code: 'CHK-026',
      message:
        '<ValuesToValidate - compareAmountReceived>: The value sent is different from the one at checkout'
    },
    {
      show_code: 'CHK-027',
      message:
        '<ValuesToValidate - compareWindowsReceived>: The windows sent are different from those at checkout'
    },
    {
      show_code: 'CHK-028',
      message:
        '<TransactionRepositoryVtex - findInfoTransaction>: error when trying to find transaction'
    },
    {
      show_code: 'CHK-029',
      message:
        '<TransactionRepositoryVtex - findInfoTransaction>: no cookie on response'
    },
    {
      show_code: 'CHK-030',
      message:
        '<TransactionRepositoryVtex - createOrder>: error when trying to create order'
    },
    {
      show_code: 'CHK-035',
      message: 'the giftcard does not have enough value to make the payment'
    },
    {
      show_code: 'CHK-040',
      message: 'error when searching for the created order'
    },
    {
      show_code: 'CHK-041',
      message: 'error when make callback for the created order in vtex'
    }
  ]
}

export const ERROR_UNIPAY_MAP_MARKS = [
  {
    code_errors: ['CHK-UNI-14'],
    tag_event: 'cliente_moroso '
  },
  {
    code_errors: ['CHK-UNI-02', 'CHK-UNI-03'],
    tag_event: 'cuotas'
  },
  {
    code_errors: ['CHK-UNI-05', 'CHK-UNI-06'],
    tag_event: 'otp'
  },
  {
    code_errors: [
      'CHK-UNI-07',
      'CHK-UNI-19',
      'CHK-UNI-22',
      'CHK-UNI-25',
      'CHK-UNI-26'
    ],
    tag_event: 'problemas_externos'
  },
  {
    code_errors: ['CHK-UNI-20', 'CHK-UNI-21'],
    tag_event: 'rut_sin_info'
  },
  {
    code_errors: ['CHK-UNI-18'],
    tag_event: 'saldo_insfuciente'
  },
  {
    code_errors: [
      'CHK-UNI-09',
      'CHK-UNI-11',
      'CHK-UNI-13',
      'CHK-UNI-16',
      'CHK-UNI-23'
    ],
    tag_event: 'tarjeta_bloqueada'
  },
  {
    code_errors: [
      'CHK-UNI-10',
      'CHK-UNI-12',
      'CHK-UNI-15',
      'CHK-UNI-17',
      'CHK-UNI-24'
    ],
    tag_event: 'validación_tarjetas'
  }
]
