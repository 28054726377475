import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-components/shared/helpers/isValidArrayWithData'
import {
  replaceStrings,
  dataLayerUniqueTypeEvent
} from '@smu-chile/pkg-unimarc-hooks'
import {
  ICheckoutV2Response,
  IPaymentInfo
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICheckoutV2'
import {
  ICart,
  ICartItem,
  ICartProduct
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { CARD_TYPE_BY_ID_SPANISH } from 'shared/constants'

interface IPaymentInfoProduct {
  'precio-lista': number
  ahorro: number
  index: number
  item_brand: string
  item_id: string
  item_list_id: string
  item_list_name: string
  item_name: string
  oferta: number
  price: number
  quantity: number
  item_category: string
  item_category2?: string
  item_category3?: string
  stock: number
}

/**
 * Retrieves the categories of a product from a cart item.
 *
 * @param {ICartItem} item - The cart item containing product information.
 * @returns {string[] | []} An array of category strings or an empty array if no description is found.
 *
 * @example
 * // Example usage:
 * const cartItem = { description: { categories: { cat1: 'Electronics', cat2: 'Home Appliances' } } };
 * const categories = getProductCategories(cartItem);
 * console.log(categories); // ['Electronics', 'Home Appliances']
 */
export const getProductCategories = (item: ICartItem): string[] | [] => {
  if (!item?.description) return []
  return Object.values(item.description.categories)
}

/**
 * Maps a product to its corresponding payment information.
 *
 * @param {ICartProduct} product - The cart product to be mapped.
 * @returns {IPaymentInfoProduct[]} An array of payment information objects for each item in the product.
 *
 * @example
 * // Example usage:
 * const product = {
 *   items: [
 *     {
 *       price: { listPrice: '100', sellingPrice: '80', quantity: 2 },
 *       description: {
 *         brandName: 'Brand A',
 *         id: '12345',
 *         name: 'Product A',
 *         categories: { cat1: 'Category 1', cat2: 'Category 2' }
 *       }
 *     }
 *   ]
 * };
 * const paymentInfo = mapProductToPaymentInfo(product);
 * console.log(paymentInfo);
 */
export const mapProductToPaymentInfo = (
  product: ICartProduct
): IPaymentInfoProduct[] => {
  return product.items.map((item, index) => {
    const savings =
      replaceStrings(item.price.listPrice) -
      replaceStrings(item.price.sellingPrice)
    const categories = getProductCategories(item)

    return {
      'precio-lista': replaceStrings(item.price.listPrice),
      ahorro: savings,
      index: index,
      item_brand: item.description.brandName,
      item_id: item.description.id,
      item_list_id: item?.tagInfo,
      item_list_name: item?.tagInfo,
      item_name: item.description.name,
      oferta: savings > 0 ? 1 : 0,
      price: replaceStrings(item.price.sellingPrice),
      quantity: item.price.quantity ?? 1,
      item_category: categories[0],
      item_category2: categories[1],
      item_category3: categories[2],
      stock: item?.price?.cartLimit > 0 ? 1 : 0
    }
  })
}

/**
 * Retrieves payment information for products in the cart that belong to the 'todos' category.
 *
 * @param {ICart | []} cart - The shopping cart containing products.
 * @returns {IPaymentInfoProduct[]} An array of payment information objects for the valid products.
 *
 * @example
 * // Example usage:
 * const cart = {
 *   products: [
 *     {
 *       categoryName: 'todos',
 *       items: [
 *         {
 *           price: { listPrice: '100', sellingPrice: '80', quantity: 2 },
 *           description: {
 *             brandName: 'Brand A',
 *             id: '12345',
 *             name: 'Product A',
 *             categories: { cat1: 'Category 1', cat2: 'Category 2' }
 *           }
 *         }
 *       ]
 *     }
 *   ]
 * };
 * const paymentInfoProducts = getAddPaymentInfoProducts(cart);
 * console.log(paymentInfoProducts);
 */
export const getAddPaymentInfoProducts = (
  cart: ICart | []
): IPaymentInfoProduct[] => {
  if (!cart || !isValidArrayWithData((cart as ICart)?.products)) return []

  return (cart as ICart).products
    .filter((product) => {
      return product?.categoryName?.toLowerCase() === 'todos'
    })
    .flatMap((product) => {
      return mapProductToPaymentInfo(product)
    })
}

/**
 * Retrieves the payment type from the payment information.
 *
 * @param {IPaymentInfo} paymentInfo - The payment information object.
 * @returns {string} The name of the selected payment method, or 'Giftcard' if no payment method is selected.
 *
 * @example
 * // Example usage:
 * const paymentInfo = { selectedPayments: [{ name: 'Credit Card' }] };
 * const paymentType = getPaymentType(paymentInfo);
 * console.log(paymentType); // 'Credit Card'
 */
export const getPaymentType = (paymentInfo: IPaymentInfo): string => {
  const selectedPaymentName = paymentInfo?.selectedPayments?.[0]?.name
  if (!selectedPaymentName) return 'Giftcard'
  return selectedPaymentName
}

/**
 * Retrieves the card type by its ID from the payment information.
 *
 * @param {IPaymentInfo} paymentInfo - The payment information object.
 * @returns {string} The card type corresponding to the selected payment ID, or a default card type if no ID is found and gift cards are present.
 *
 * @example
 * // Example usage:
 * const paymentInfo = { selectedPayments: [{ id: '12' }], giftCards: [] };
 * const cardType = getCardTypeById(paymentInfo);
 * console.log(cardType); // The card type corresponding to ID '12'
 */
export const getCardTypeById = (paymentInfo: IPaymentInfo): string => {
  const selectedPaymentsId = paymentInfo?.selectedPayments?.[0]?.id
  const hasGiftcards = isValidArrayWithData(paymentInfo?.giftCards)
  if (!selectedPaymentsId && hasGiftcards) return CARD_TYPE_BY_ID_SPANISH['17']
  return CARD_TYPE_BY_ID_SPANISH[selectedPaymentsId]
}

/**
 * Adds a payment info event to the data layer with relevant details from the checkout and cart.
 *
 * @param {Object} params - The parameters for the event.
 * @param {ICheckoutV2Response} params.checkout - The checkout response object.
 * @param {ICart} params.cart - The shopping cart object.
 * @param {string} params.orderformId - The ID of the order form.
 * @returns {void}
 *
 * @example
 * // Example usage:
 * const checkout = {
 *   shipping: { type: 'residential', address: { neighborhood: 'Central' } },
 *   paymentInfo: { selectedPayments: [{ cardType: 'Visa', name: 'Credit Card' }] },
 *   value: '10000',
 *   coupon: [{ code: 'DISCOUNT10' }]
 * };
 * const cart = {
 *   products: [
 *     {
 *       categoryName: 'todos',
 *       items: [
 *         {
 *           price: { listPrice: '100', sellingPrice: '80', quantity: 2 },
 *           description: {
 *             brandName: 'Brand A',
 *             id: '12345',
 *             name: 'Product A',
 *             categories: { cat1: 'Category 1', cat2: 'Category 2' }
 *           }
 *         }
 *       ]
 *     }
 *   ]
 * };
 * const orderformId = 'ABC123';
 * addPaymentInfoEvent({ checkout, cart, orderformId });
 */
export const addPaymentInfoEvent = ({
  checkout,
  cart,
  orderformId,
  saleschannel,
  session_store
}: {
  checkout: ICheckoutV2Response
  cart: ICart
  orderformId: string
  saleschannel: string
  session_store: string
}): void => {
  const isDelivery = checkout.shipping?.type === 'residential'
  const addPaymentInfoProducts = getAddPaymentInfoProducts(cart)

  dataLayerUniqueTypeEvent({
    session_store,
    event: 'add_payment_info',
    saleschannel,
    card_type:
      getCardTypeById(checkout.paymentInfo) ??
      'the service doesnt have a cardType assigned',
    currency: 'CLP',
    value: replaceStrings(checkout.value),
    orderform_id: orderformId,
    assigned_store: `Unimarc ${checkout.shipping?.address?.neighborhood}`,
    courier_id: checkout.shipping?.address?.courierId,
    shipping_tier: isDelivery ? 'Despacho a domicilio' : 'Retiro en tienda',
    payment_type: getPaymentType(checkout.paymentInfo),
    coupon: checkout.coupon?.[0]?.code,
    guardar_tarjeta: 'n/d',
    shipping_date: checkout?.selectedDeliveryWindows?.[0]?.startDateUtc,
    ecommerce: {
      items: addPaymentInfoProducts
    }
  })
}
