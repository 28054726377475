import { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Column,
  InputLabel,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  patchCoupons,
  replaceStrings,
  trigger,
  useCheckoutV2
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig, useDataLayers } from 'shared/hooks'
import { EMOJIS, TOAST_MESSAGES } from 'shared/constants'
import { DiscountsBackbone } from './DiscountsBackbone'
import { IGiftCard } from '../PaymentMethods/GiftcardComponent'
import styles from './styles.module.css'

const initialForm = {
  discountCode: ''
}

const messageError = {
  404: TOAST_MESSAGES.error_not_found_coupon,
  410: TOAST_MESSAGES.error_expired_coupon,
  304: TOAST_MESSAGES.error_already_used_coupon
}

export const Discounts = () => {
  const [formDiscounts, setFormDiscounts] = useState(initialForm)
  const [isLoading, setIsLoading] = useState(false)

  const { checkoutConfig } = useCheckoutConfig()
  const { data: checkout, temporalMutate } = useCheckoutV2()
  const { getErrorEvent } = useDataLayers()

  const buttonDiscountStatus =
    formDiscounts.discountCode?.length > 2 ? 'initial' : 'disabled'
  const hasDiscount = isValidArrayWithData(checkout?.coupon)

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value

    setFormDiscounts((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleClearForm = () => {
    setFormDiscounts(initialForm)
  }

  const handleApplyDiscount = async () => {
    try {
      setIsLoading(true)
      const coupon = hasDiscount ? '' : formDiscounts.discountCode

      const response = await patchCoupons({
        coupons: [coupon],
        availablePaymentMethods:
          checkout?.paymentInfo?.availablePaymentMethods?.map((method) => {
            return {
              id: method.id,
              name: method.name,
              price: method.price
            }
          }) || []
      })

      if (response.status !== 200) {
        const errorCode = getErrorEvent({
          endpoint: 'coupon',
          error: response?.data.error
        })

        trigger({
          eventType: 'checkoutToast',
          data: {
            show: true,
            success: true,
            codeError:
              errorCode?.show_code ||
              response?.data?.code ||
              response?.status?.toString(),
            toastMessage:
              messageError[response.status] ??
              TOAST_MESSAGES.error_general_coupon
          }
        })
        temporalMutate({ coupon: null })
        handleClearForm()
      } else {
        if (!hasDiscount) {
          trigger({
            eventType: 'checkoutToast',
            data: {
              show: true,
              success: true,
              toastMessage: TOAST_MESSAGES.success_added_coupon
            }
          })
        }
        let findGiftCardSelected: IGiftCard
        if (isValidArrayWithData(checkout?.paymentInfo?.giftCards)) {
          // look if giftcard was selected as payment method
          findGiftCardSelected =
            checkout?.paymentInfo?.giftCards.find((giftCard) => {
              return giftCard.isActive
            }) || {}
          // validate if gf's balance is enough to pay
          findGiftCardSelected.hasEnoughAmount =
            replaceStrings(findGiftCardSelected?.balance) >
            replaceStrings(response?.data?.value)
        }

        temporalMutate({
          paymentInfo: {
            ...checkout?.paymentInfo,
            giftCards: findGiftCardSelected
              ? [{ ...findGiftCardSelected }]
              : checkout?.paymentInfo?.giftCards,
            availablePaymentMethods: response?.data?.availablePaymentMethods
          },
          totals: response?.data?.totals,
          value: response?.data?.value,
          coupon: hasDiscount ? null : [{ code: coupon }]
        })
        if (hasDiscount) handleClearForm()
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (checkout?.coupon?.length > 0) {
      setFormDiscounts((prev) => {
        return {
          ...prev,
          discountCode: checkout.coupon[0].code
        }
      })
    }
  }, [checkout])

  if (checkoutConfig.isLoading) {
    return <DiscountsBackbone />
  }

  return (
    <>
      <BigScreen>
        <Column
          customClassName={styles.container}
          tagName='section'
        >
          <Row tagName='article'>
            <Text
              fontSize='xl'
              fontWeight='medium'
              id={getBemId('discounts', 'title', 'desktop')}
              lineHeight='20px'
            >
              Descuéntalos a tu compra {EMOJIS.STAR_EYES}
            </Text>
          </Row>
          <Spacer.Horizontal customSize={32} />
          <Column
            position='relative'
            tagName='article'
          >
            <Row
              alignItems='center'
              borderRadius={getGlobalStyle('--border-radius-md')}
              clickable='pointer'
              hidden
              id={getBemId('discounts', 'containerUnicreditos', 'desktop')}
              justifyContent='between'
              padding='16px'
            >
              <Row
                alignItems='center'
                clickable='pointer'
                gap={12}
              >
                <Picture
                  hasURL
                  height='40px'
                  id={getBemId('discounts', 'imageUnicreditos', 'desktop')}
                  src='/images/cash.png'
                  width='40px'
                />
                <Column clickable='pointer'>
                  <Text
                    clickable='pointer'
                    fontWeight='semibold'
                    id={getBemId('discounts', 'textUnicreditos', 'desktop')}
                  >
                    Unicréditos
                  </Text>
                  <Text
                    clickable='pointer'
                    fontSize='md'
                    fontWeight='regular'
                    id={getBemId('discounts', 'amountUnicreditos', 'desktop')}
                  >
                    Total:$3.500
                  </Text>
                </Column>
              </Row>
              <Row
                clickable='pointer'
                gap={9}
                maxWidth='max-content'
              >
                <Text
                  clickable='pointer'
                  fontSize='xl'
                  fontWeight='semibold'
                >
                  $3.500
                </Text>
                <Checkbox
                  onClick={() => {
                    return
                  }}
                  type='round'
                  value={false}
                />
              </Row>
            </Row>
            {/* discount code */}
            <Row
              alignItems='center'
              borderRadius={getGlobalStyle('--border-radius-md')}
              justifyContent='between'
              padding='0 16px'
            >
              <Row
                alignItems='center'
                clickable='pointer'
                gap={12}
                width='auto'
              >
                <Picture
                  hasURL
                  height='40px'
                  src='/images/gift.png'
                  width='40px'
                />
                <InputLabel
                  appendIcon={hasDiscount ? 'CheckCircle' : undefined}
                  appendIconColor={getGlobalStyle(
                    '--color-feedback-success-light'
                  )}
                  customClassName={styles['input-discount-code']}
                  disabled={isLoading || hasDiscount}
                  id={getBemId('discounts', 'input-discount-code')}
                  inputWidth='250px'
                  label='Código de descuento'
                  name='discountCode'
                  onChange={handleFormChange}
                  onClickIcon={handleClearForm}
                  showXIcon={!hasDiscount}
                  value={formDiscounts.discountCode}
                  variant='compact'
                />
              </Row>
              {formDiscounts.discountCode?.length > 0 && (
                <Row
                  clickable='pointer'
                  maxWidth='max-content'
                >
                  <Button
                    color={
                      buttonDiscountStatus === 'initial'
                        ? getGlobalStyle('--color-primary-light-red')
                        : ''
                    }
                    fontWeight={getGlobalStyle('--font-weight-medium')}
                    id={getBemId('discounts', 'button-apply-discount')}
                    isLoading={isLoading}
                    label={hasDiscount ? 'Borrar' : 'Agregar'}
                    minWidth='max-content'
                    onClick={handleApplyDiscount}
                    status={buttonDiscountStatus}
                    themePointLoader='red'
                    type='plain'
                  />
                </Row>
              )}
            </Row>
          </Column>
          <Spacer.Horizontal customSize={32} />
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column
          customClassName={styles.container}
          tagName='section'
        >
          <Row>
            <Text
              fontSize='lg'
              fontWeight='medium'
              id={getBemId('discounts', 'title', 'mobile')}
              lineHeight='20px'
            >
              Descuéntalos a tu compra {EMOJIS.STAR_EYES}
            </Text>
          </Row>
          <Spacer.Horizontal customSize={32} />
          <Column
            gap={16}
            position='relative'
          >
            {/* unicreditos */}
            <Row
              alignItems='center'
              borderRadius={getGlobalStyle('--border-radius-md')}
              clickable='pointer'
              customClassName={styles['option-method-card']}
              hidden
              justifyContent='between'
              padding='16px'
            >
              <Row
                alignItems='center'
                clickable='pointer'
                gap={12}
                id={getBemId('discounts', 'containerUnicreditos', 'mobile')}
              >
                <Picture
                  height='28px'
                  id={getBemId('discounts', 'imageUnicreditos', 'mobile')}
                  src='/images/cash.png'
                  width='28px'
                />
                <Column>
                  <Text
                    fontSize='md'
                    fontWeight='semibold'
                    id={getBemId('discounts', 'textUnicreditos', 'mobile')}
                  >
                    Unicréditos
                  </Text>
                  <Text
                    fontSize='sm'
                    id={getBemId('discounts', 'amountUnicreditos', 'mobile')}
                  >
                    Total:$3.500
                  </Text>
                </Column>
              </Row>
              <Row
                clickable='pointer'
                gap={9}
                maxWidth='max-content'
              >
                <Text fontSize='lg'>$3.500</Text>
                <Checkbox
                  onClick={() => {
                    return
                  }}
                  type='round'
                  value={false}
                />
              </Row>
            </Row>
            {/* discount code */}
            <Row
              alignItems='center'
              borderRadius={getGlobalStyle('--border-radius-md')}
              clickable='pointer'
              justifyContent='between'
              padding='0 16px'
            >
              <Row
                alignItems='center'
                clickable='pointer'
                gap={12}
                maxWidth='288px'
              >
                <Picture
                  height='24px'
                  src='/images/gift.png'
                  width='24px'
                />
                <InputLabel
                  appendIcon={hasDiscount ? 'CheckCircle' : undefined}
                  appendIconColor={getGlobalStyle(
                    '--color-feedback-success-light'
                  )}
                  customClassName={styles['input-discount-code']}
                  disabled={isLoading || hasDiscount}
                  fontSize='md'
                  id={getBemId('discounts', 'input-discount-code')}
                  label='Código de descuento'
                  name='discountCode'
                  onChange={handleFormChange}
                  onClickIcon={handleClearForm}
                  showXIcon={!hasDiscount}
                  value={formDiscounts.discountCode}
                  variant='compact'
                />
              </Row>
              {formDiscounts.discountCode?.length > 0 && (
                <Row
                  clickable='pointer'
                  maxWidth='max-content'
                >
                  <Button
                    color={
                      buttonDiscountStatus === 'initial'
                        ? getGlobalStyle('--color-primary-light-red')
                        : ''
                    }
                    fontSize={getGlobalStyle('--font-size-md')}
                    fontWeight={getGlobalStyle('--font-weight-semibold')}
                    id={getBemId('discounts', 'button-apply-discount')}
                    isLoading={isLoading}
                    label={hasDiscount ? 'Borrar' : 'Agregar'}
                    minWidth='max-content'
                    onClick={handleApplyDiscount}
                    status={buttonDiscountStatus}
                    themePointLoader='red'
                    type='plain'
                  />
                </Row>
              )}
            </Row>
          </Column>
          <Spacer.Horizontal customSize={32} />
        </Column>
      </SmallScreen>
    </>
  )
}
