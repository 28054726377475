import { useEffect, useState } from 'react'
import {
  DesktopModalWrapper,
  MobileModalWrapper
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  dataLayerUniqueTypeEvent,
  postCard
} from '@smu-chile/pkg-unimarc-hooks'
import {
  PAYMENT_METHODS_ORDER_TO_SHOW,
  PAYMENT_ONECLICK_FORMAT,
  PAYMENT_PROVIDERS_DICTIONARY_CODE
} from 'shared/constants'
import { Body, Footer } from './components'
import styles from '../styles.module.css'

interface IModalSaveCardsProps {
  handleGoBack?: () => void
  currentPaymentMethod?: string
}

export const ModalSaveCard = ({
  handleGoBack,
  currentPaymentMethod
}: IModalSaveCardsProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const paymentMethodSelected = PAYMENT_METHODS_ORDER_TO_SHOW.find((item) => {
    return item.codes.includes(currentPaymentMethod)
  })

  const handleSelectPaymentMethod = async (saveCard: boolean) => {
    setIsLoading(true)
    const providerToSend =
      PAYMENT_PROVIDERS_DICTIONARY_CODE[currentPaymentMethod]
    const getDomain = window.location.origin

    dataLayerUniqueTypeEvent({
      event: 'interacciones_checkout',
      categoria_evento: 'guardar_tarjeta',
      accion_evento: 'clic',
      etiqueta_evento: saveCard ? 'boton_guardar' : 'boton_eliminar',
      payment_type: PAYMENT_ONECLICK_FORMAT,
      card_type: paymentMethodSelected?.title
    })

    const response = await postCard({
      provider: providerToSend,
      inscriptionErrorUrl: `${getDomain}?inscriptionError=true`,
      inscriptionSuccessUrl: `${getDomain}?inscriptionSuccess=true`,
      saveCardOption: saveCard
    })

    setIsLoading(false)
    if (typeof response === 'string') {
      const temporalDiv = document.createElement('div')
      temporalDiv.id = 'temporalDiv'
      temporalDiv.innerHTML = response
      document?.body?.appendChild(temporalDiv)
      document?.getElementById('temporalDiv')?.querySelector('form')?.submit()
    }
  }

  const handleClickToggle = () => {
    dataLayerUniqueTypeEvent({
      event: 'interacciones_checkout',
      categoria_evento: 'guardar_tarjeta',
      accion_evento: 'clic',
      etiqueta_evento: 'cerrar_modal',
      payment_type: PAYMENT_ONECLICK_FORMAT,
      card_type: paymentMethodSelected?.title
    })
    handleGoBack()
  }

  useEffect(() => {
    dataLayerUniqueTypeEvent({
      event: 'interacciones_checkout',
      categoria_evento: 'guardar_tarjeta',
      accion_evento: 'vista',
      etiqueta_evento: 'modal',
      payment_type: PAYMENT_ONECLICK_FORMAT,
      card_type: paymentMethodSelected?.title
    })
  }, [])

  return (
    <>
      <BigScreen>
        <DesktopModalWrapper
          blockId='modalListNewPaymentsDesktop'
          bodyChildrenProps={{
            padding: '0',
            customHeight: '100%',
            maxHeight: '100%',
            overflow: 'hidden'
          }}
          bodyCustomClass={styles.container_body}
          borderEdge='none'
          footerChildrenProps={{
            default: false,
            boxShadow: 'none',
            children: (
              <Footer
                handleDelete={() => {
                  handleSelectPaymentMethod(false)
                }}
                handleSave={() => {
                  handleSelectPaymentMethod(true)
                }}
                isLoading={isLoading}
              />
            )
          }}
          headerCloseIcon={{
            customSize: 20,
            default: true,
            name: 'CloseThin'
          }}
          headerTitleProps={{
            fontWeight: 'medium'
          }}
          modalConfigsProps={{
            isWindowBlocked: true,
            maxHeight: '440px',
            minHeight: '440px',
            isOpen: true,
            toggle: handleClickToggle,
            toggleOutside: handleClickToggle
          }}
          styleProps={{
            customHeightModalContent: '100%'
          }}
        >
          <Body />
        </DesktopModalWrapper>
      </BigScreen>
      <SmallScreen>
        <MobileModalWrapper
          blockId='modalListNewPaymentsMobile'
          body={<Body />}
          customFooter={
            <Footer
              handleDelete={() => {
                handleSelectPaymentMethod(false)
              }}
              handleSave={() => {
                handleSelectPaymentMethod(true)
              }}
              isLoading={isLoading}
            />
          }
          dragProps={{
            draggButton: false
          }}
          headerCloseIconProps={{
            customSize: 20,
            name: 'CloseThin'
          }}
          headerProps={{
            padding: '3px 16px'
          }}
          hideHeaderDivider={false}
          iconColor={getGlobalStyle('--color-base-black')}
          iconSize={20}
          isEnableButton
          modalConfigsProps={{
            openAnimation: true,
            fullScreenSticky: true,
            hiddenX: true,
            isAutoHeight: true,
            isOpen: true,
            isWindowBlocked: true,
            marginFullScreen: '1vh 0 0',
            stickyBottom: true,
            toggle: handleClickToggle,
            toggleOutside: handleClickToggle
          }}
          onClose={handleClickToggle}
          styleProps={{
            padding: '0',
            height: '100%',
            borderRadius: `${getGlobalStyle(
              '--border-radius-md'
            )} ${getGlobalStyle('--border-radius-md')} 0 0`
          }}
        />
      </SmallScreen>
    </>
  )
}
