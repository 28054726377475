import { Button, Column, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface IFooterModalProps {
  isLoadingRemoveProducts: boolean
  onChangePaymentMethod: () => void
  onRemoveProducts: () => void
}

export const FooterModal = ({
  isLoadingRemoveProducts,
  onChangePaymentMethod,
  onRemoveProducts
}: IFooterModalProps) => {
  return (
    <Column>
      <Button
        borderRadius='24px'
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId(
          `modal-products-not-allowed-desktop-modal-wrapper`,
          'button-remove'
        )}
        isLoading={isLoadingRemoveProducts}
        label='Eliminar productos'
        onClick={onRemoveProducts}
        padding='8px 16px'
      />
      <Spacer.Horizontal customSize={16} />
      <Button
        background={getGlobalStyle('--color-base-white')}
        border={`1px solid ${getGlobalStyle('--color-base-black')}`}
        borderRadius='24px'
        color={getGlobalStyle('--color-base-black')}
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId(
          `modal-products-not-allowed-desktop-modal-wrapper`,
          'button-change-payment-method'
        )}
        label='Cambiar medio de pago'
        onClick={onChangePaymentMethod}
        padding='8px 16px'
      />
    </Column>
  )
}
