import { useState } from 'react'
import { useCheckoutConfig } from 'shared/hooks'
import {
  Column,
  InputSwitch,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import styles from './index.module.css'

export const Invoice = () => {
  const [InputCheckbox, setInputCheckbox] = useState(false)

  const { checkoutConfig } = useCheckoutConfig()
  const { isMobileV2 } = useMobile()

  const currentStore = checkoutConfig?.saleschannel
  const invoiceStores = checkoutConfig?.invoiceStores
  const isInvoiceEnabled =
    (isValidArrayWithData(invoiceStores) &&
      invoiceStores?.some((store) => {
        return parseInt(store?.nombre) === parseInt(currentStore)
      })) ??
    false

  if (!isInvoiceEnabled) {
    return null
  }

  return (
    <Row
      alignItems='center'
      customClassName={styles.container}
      justifyContent='between'
      padding={isMobileV2 ? '4px 0px 24px 0px' : '4px 12px 24px 12px'}
    >
      <Column maxWidth='max-content'>
        <Text
          fontSize='base'
          fontWeight='medium'
        >
          ¿Necesitas factura?
        </Text>
      </Column>
      <Column maxWidth='max-content'>
        <InputSwitch
          onClick={(value) => {
            setInputCheckbox(value)
          }}
          value={InputCheckbox}
        />
      </Column>
    </Row>
  )
}
