import { Dispatch, SetStateAction } from 'react'
import {
  Button,
  ButtonStatus,
  Column,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  postTransaction,
  postValidateSc,
  sleep,
  trigger,
  useCheckoutV2,
  useMutation,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'
import { useCheckoutConfig, useSelectedCard } from 'shared/hooks'
import { INTERNAL_SERVER_ERROR } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { TOAST_MESSAGES } from 'shared/constants'
import { useLayoutContext } from 'components/Layout/context'
import { PayBtnLoader } from './PayBtnLoader'
import { addPaymentInfoEvent } from './helpers'

interface PayBtnProps {
  isLoadingButton: boolean
  setIsLoadingButton: Dispatch<SetStateAction<boolean>>
}

export const PayBtn = ({
  isLoadingButton,
  setIsLoadingButton
}: PayBtnProps) => {
  const { checkoutConfig } = useCheckoutConfig()
  const { data: checkout } = useCheckoutV2()
  const { data: cart } = useNewCartUnimarc()
  const { selectedCard } = useSelectedCard()
  const { setShowHeaderAndFooter } = useLayoutContext() || {}
  const transactionData = useMutation(postTransaction, {
    mutationKey: 'transaction',
    onSuccess: async (response) => {
      if (response?.status === INTERNAL_SERVER_ERROR || response?.data?.code) {
        setShowHeaderAndFooter(true)
        setIsLoadingButton(false)

        const deliveryWindowErrors = ['CHK-024', 'CHK-027']
        if (deliveryWindowErrors.includes(response?.data?.code)) {
          trigger({
            eventType: 'toggleErrorDeliveryWindow',
            data: {
              code: response?.data?.code,
              show: true
            }
          })
          return
        }

        const errorOneClickData = ['CHK-051']
        if (errorOneClickData.includes(response?.data?.code)) {
          trigger({
            eventType: 'checkoutToast',
            data: {
              codeError: response?.data?.code || 'error',
              show: true,
              toastMessage: TOAST_MESSAGES.error_oneclick_data
            }
          })
          return
        }

        if (isValidArrayWithData(response?.data?.detail?.conflicts)) {
          trigger({
            eventType: 'toggleProductsNotAllowGiftcard',
            data: {
              show: true
            }
          })
          return
        }

        trigger({
          eventType: 'checkoutToast',
          data: {
            codeError: response?.data?.code || 'error',
            show: true,
            toastMessage: TOAST_MESSAGES.error_transaction
          }
        })
      } else {
        const responseValidateSc = await postValidateSc({
          geoCoordinates: checkout?.shipping?.address?.geoCoordinates
        })
        // trigger add_payment_info tagmanager event to tracking purchase KPI
        addPaymentInfoEvent({
          checkout,
          cart,
          orderformId,
          saleschannel,
          session_store: responseValidateSc?.data?.data?.id
        })
        await sleep(800)
        // prepare url redirect
        const orderId = response?.data?.orderId
        const url = `${process.env.NEXT_PUBLIC_ORDERPLACED}/?og=${orderId}`
        window.location.href = url
      }
    }
  })

  const orderformId = checkoutConfig?.orderformId
  const saleschannel = checkoutConfig?.saleschannel
  const isEnableButton = checkoutConfig.isEnablePaymentButton
  const isLoadingPayBtn = checkoutConfig.loadingTransaction
  const buttonStatus: ButtonStatus = isEnableButton ? 'initial' : 'disabled'
  const buttonColor = isEnableButton && getGlobalStyle('--color-primary-red2')
  const totalPayment = checkout?.value

  // refetch in the client if no query cache from the server exist
  const handleTransaction = async () => {
    setShowHeaderAndFooter(false)
    try {
      setIsLoadingButton(true)
      const transactionForm: Parameters<typeof transactionData.mutate>[0] = {
        value: totalPayment,
        selectedDeliveryWindows: [{ ...checkout?.selectedDeliveryWindows?.[0] }]
      }
      if (selectedCard?.quotes) {
        transactionForm.additionalData = {
          quotas: parseInt(checkoutConfig.installment_oneclick_chosen)
        }
      }
      transactionData.mutate(transactionForm)
    } catch (error) {
      setIsLoadingButton(false)
      // eslint-disable-next-line no-console
      console.error({ error })
    }
  }

  if (checkoutConfig.isLoading) {
    return <PayBtnLoader />
  }

  return (
    <>
      <BigScreen>
        <Column
          gap={20}
          id={getBemId('btn', 'btn-pay')}
          maxWidth='375px'
        >
          <Row>
            <Button
              background={buttonColor}
              borderRadius={getGlobalStyle('--border-radius-xl')}
              fontSize={getGlobalStyle('--font-size-base')}
              fontWeight={getGlobalStyle('--font-weight-medium')}
              height='40px'
              isLoading={isLoadingButton || isLoadingPayBtn}
              label={`Pagar ${totalPayment}`}
              onClick={handleTransaction}
              padding='10px 20px'
              status={buttonStatus}
              width='100%'
            />
          </Row>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column
          gap={20}
          id={getBemId('btn', 'btn-pay')}
        >
          <Row>
            <Button
              background={buttonColor}
              borderRadius={getGlobalStyle('--border-radius-xl')}
              isLoading={isLoadingButton || isLoadingPayBtn}
              label={`Pagar ${totalPayment}`}
              onClick={handleTransaction}
              status={buttonStatus}
              width='100%'
            />
          </Row>
        </Column>
      </SmallScreen>
    </>
  )
}
