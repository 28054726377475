import { Button, Column, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'

export interface IFooterModalProps {
  onChangePaymentMethod: () => void
  onMoveToCart: () => void
}

export const FooterModal = ({
  onChangePaymentMethod,
  onMoveToCart
}: IFooterModalProps) => {
  return (
    <Column>
      <Button
        borderRadius='24px'
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId(
          'modal-unipay-value-get-over-wrapper',
          'button-change-payment-method'
        )}
        label='Cambiar medio de pago'
        onClick={onChangePaymentMethod}
        padding='8px 16px'
      />
      <Spacer.Horizontal customSize={16} />
      <Button
        background={getGlobalStyle('--color-base-white')}
        border={`1px solid ${getGlobalStyle('--color-base-black')}`}
        borderRadius='24px'
        color={getGlobalStyle('--color-base-black')}
        fontWeight={getGlobalStyle('--font-weight-medium')}
        fullWidth
        id={getBemId(
          'modal-unipay-value-get-over-wrapper',
          'button-move-to-cart'
        )}
        label='Modificar mi carro'
        onClick={onMoveToCart}
        padding='8px 16px'
      />
    </Column>
  )
}
